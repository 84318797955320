<template>
  <div class="create-content-main">
    <div class="create-form">
      <h2 v-if="id === null || id === undefined" class="p-4">章登録</h2>
      <h2 v-else class="p-4">編集</h2>
      <form
        class="p-3"
        v-on:submit.prevent="CreateNewContent()"
        id="create-content"
        autocomplete="off"
      >
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold custom-text">NO</label>
          <div class="cp-4 w-100">
            <b-form-input v-model="NO" disabled required></b-form-input>
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold custom-text"
            >章タイトル<span class="textMust">（必須）</span></label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="chapter_title"
            />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold custom-text"
            >章ID<span class="textMust">（必須）</span></label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="chapter_ID"
            />
          </div>
        </div>
      </form>
      <div class="d-flex justify-content-center mt-4 pb-4">
        <b-button
          v-on:click.prevent="CreateNewChapter()"
          :disabled="isLoading"
          variant="info"
          class="mr-4"
          >登録・更新する</b-button
        >
        <b-button
          variant="secondary"
          class="mr-4 back-btn"
          v-on:click="returnList()"
          >戻る</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { Constants } from "../../utils/constants";
export default {
  name: "CreateCategory",
  data() {
    return {
      no: "",
      chapter_title: "",
      chapter_ID: "",
      isLoading: false,
      listContentOptions: [],
      list_content: [],
      errorContentIdRequired: false,
      loadingContent: false,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      page: 2,
      NO: "",
      id: this.$route.params.id,
      data: {
        chapter_id: "ID5",
        chapter_title: "章タイトル",
        created_at: "2022-02-24T15:00:00.000000Z",
        created_by: 1,
        deleted_at: null,
        id: 5,
        shop_id: 1,
        updated_at: null,
        updated_by: null,
      },
    };
  },
  created() {
    this.NO = this.$route.query.no;
    if (this.$route.params.id !== undefined) {
      this.chapter_ID = this.data.chapter_id;
      this.chapter_title = this.data.chapter_title;
    } else {
      this.chapter_ID = "";
      this.chapter_title = "";
    }
    this.getChapterNewById(this.$route.params.id);
    this.$store.commit("set", ["success", false]);
    this.$store.commit("set", ["message", ""]);
    this.$store.commit("set", ["error", false]);
  },
  computed: {
    ...mapGetters(["chapterById", "success", "message", "error"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.isLoading = false;
      }
    },
    error() {
      if (this.error) {
        this.isLoading = false;
      }
    },
    chapterById() {
      if (this.chapterById !== null) {
        this.chapter_title = this.chapterById.title;
        this.chapter_ID = this.chapterById.id_chapter;
      }
    },
  },
  methods: {
    ...mapActions({
      getChapterNewById: "getChapterNewById",
      createChapterNew: "createChapterNew",
    }),
    scrollContent() {
      if (
        Math.round(
          document.getElementsByClassName("multiselect__content-wrapper")[0]
            .scrollTop +
            document.getElementsByClassName("multiselect__content-wrapper")[0]
              .clientHeight
        ) ===
        document.getElementsByClassName("multiselect__content-wrapper")[0]
          .scrollHeight
      ) {
        this.loadingContent = true;
        const requestContent = {
          data: {
            shop_id: this.shop_id,
          },
          page: this.page,
        };
        this.page += 1;
        this.getListContent(requestContent);
      }
    },
    async CreateNewChapter() {
      this.isLoading = true;
      const shop_id = this.shop_id;
      const id = this.$route.params.id;
      // タイトルは入力必須項目です。
      if (this.chapter_title === null || this.chapter_title.trim() === "") {
        this.$toasted.error("タイトルは入力必須項目です。");
        this.isLoading = false;
        return;
      }
      if (this.chapter_ID === null || this.chapter_ID.trim() === "") {
        this.$toasted.error("章IDは入力必須項目です。");
        this.isLoading = false;
        return;
      }
      const formData = {
        id: id,
        shop_id: parseInt(shop_id),
        title: this.chapter_title,
        id_chapter: this.chapter_ID,
      };
      if (id) {
        const dataReturn = await this.$store.dispatch(
          "updateChapterNew",
          formData
        );
        if (dataReturn.success) {
          this.$toasted.success(this.message);
          this.$router.go(-1);
        } else {
          this.$toasted.error(this.message);
          this.isLoading = false;
        }
      } else {
        const dataReturn = await this.$store.dispatch(
          "createChapterNew",
          formData
        );
        if (dataReturn.success) {
          this.$toasted.success(this.message);
          this.$router.go(-1);
        } else {
          this.$toasted.error(this.message);
          this.isLoading = false;
        }
      }
    },
    returnList() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.create-form {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 0px !important;
}
.textMust {
  color: #fff;
  background: red;
  padding: 2px 0px;
  border-radius: 5px;
  top: 0px;
  margin-left: 15px;
  font-size: 11px;
}
.custom-text {
  background-color: rgb(213, 213, 213);
  height: 40px;
  align-items: center;
  display: flex;
}
.row-input input {
  margin: 0px !important;
}
</style>
